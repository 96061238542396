import Vue from 'vue'

// axios
import axios from 'axios'

//const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.uzaktanokulsistemi.com/v1/' : 'https://localhost:5001/v1/';
const baseURL = 'https://api.uzaktanokulsistemi.com/v1/';
//const baseURL = 'https://api.lmszoom.com/v1/';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseURL
  //baseURL: 'https://localhost:5001/v1/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})


axiosIns.interceptors.request.use(config => {
  config.headers["Authorization"] = "Bearer "+localStorage.getItem("accessToken");
  config.headers["Content-Type"] = "application/json";
  return config;
});

axiosIns.interceptors.response.use(null, async error => {
  if (error.response.status == 401 || error.response.status == 423 || error.response.status == 403) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    alert("Oturum Sonlandırıldı");
    location.reload();
    return Promise.reject("Oturum Sonlandırıldı");
  }
  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns
