<template>
  <div class="chats">
    <div
      v-for="(msgGrp,index) in chatData"
      :key="msgGrp.clientId+String(index)"
      class="chat"
      :class="msgGrp.clientId != me.id ? 'chat-left' : null"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="msgGrp.profileImage"
        />
      </div>
      <div class="chat-body">
        <div class="chat-content" style="display: flex;flex-direction: column" >
          <span style="color:black;font-size: x-small;margin-bottom: 5px;">{{msgGrp.userName}}</span>
          <img v-if="msgGrp.messageImage!=''" :src="msgGrp.messageImage" style="width:350px" />
          <p>{{ msgGrp.messageText }}</p>
          <span style="color:gray;font-size: x-small;margin-top: 5px;">{{msgGrp.messageDate}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  data(){
    return {
      me:{}
    }
  },
  created() {
        this.me = JSON.parse(localStorage.getItem('userData'));
  },
}
</script>

<style>

</style>
